@font-face {
    font-family: "AlbertSans";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/AlbertSans-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "AlbertSans";
    font-style: italic;
    font-weight: normal;
    src: url("../fonts/AlbertSans-Italic.ttf") format("truetype");
}
@font-face {
    font-family: "AlbertSans";
    font-style: normal;
    font-weight: bold;
    src: url("../fonts/AlbertSans-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "AlbertSans";
    font-style: normal;
    font-weight: semi-bold;
    src: url("../fonts/AlbertSans-SemiBold.ttf") format("truetype");
}