.Description{
    font-weight: normal !important;
    font-size: 14px !important;
    padding-top: 20px !important;    
    padding-bottom: 20px !important;    
}
body{
    margin: 0 !important;
}
.Gap{
    padding-top: 20px;
}

.Form{
    margin-top: 10px !important;
    padding: 20px !important;    
    border: 1px solid #e0e0e0 !important;     
}

.FormTextField{
    width: 100% !important;
}

.GridPadding{
    padding-top: 20px !important;    
}

.Delete{
    float: right;
}

.Width100Per{
    width: 100% !important;
}

.ErrorMessage{
    color: red;
    font-size: 12px;
}

.PadTopBtm20{
    padding-top: 20px;
    padding-bottom: 20px;
}

.PadLeftRight10{
    padding-left: 10px;
    padding-right: 10px;
}

.PadLeftRight20{
    padding-left: 20px;
    padding-right: 20px;
}

.floatRight{
    float: right;
}

.saveSubmit{
    float: right;
}