* {
  font-family: 'AlbertSans', sans-serif !important;
}

.mrgTop10{
  margin-top: 10px !important;
}

.mrgBottom10{
  margin-bottom: 10px !important;
}

.mrgRight10{
  margin-right: 10px !important;
}

.mrgLeft10{
  margin-left: 10px !important;
}

.normalFont{
  font-style: normal;
}
